export default function popup() {
    let opinionBoxes = document.querySelectorAll(
        '.product-opinion__opinion-box'
    );

    let popup = document.getElementById('opinion-popup');
    let popupTitle = document.getElementById('opinion-popup-title');
    let popupStars = document.getElementById('opinion-popup-stars');
    let popupNumber = document.getElementById('opinion-popup-number');
    let popupText = document.getElementById('opinion-popup-text');
    let closeBtn = document.getElementsByClassName('opinion-popup__close')[0];

    if (!popup || !popupTitle || !popupStars || !popupNumber || !popupText || !closeBtn) {
        return;
    }

    opinionBoxes.forEach(function (box, index) {
        box.addEventListener('click', function () {
            let opinionTitle = box.querySelector(
                '.product-opinion__title'
            ).textContent;
            let opinionNumber = box.querySelector(
                '.product-opinion__number'
            ).textContent;
            let opinionText = box
                .querySelector('.product-opinion__description')
                .getAttribute('data-full-text');
            let opinionNumberValue = parseInt(opinionNumber);

            popupTitle.textContent = opinionTitle;
            popupNumber.textContent = opinionNumber;
            popupText.textContent = opinionText;

            popupStars.innerHTML = '';

            for (let i = 1; i <= 5; i++) {
                let star = document.createElement('span');
                star.classList.add(
                    'product-opinion__star',
                    'icon',
                    'icon-star'
                );
                if (i <= opinionNumberValue) {
                    star.classList.add('product-opinion__star--color');
                }
                popupStars.appendChild(star);
            }
            popup.style.display = 'block';
        });
    });

    closeBtn.addEventListener('click', function () {
        popup.style.display = 'none';
    });

    window.addEventListener('click', function (event) {
        if (event.target == popup) {
            popup.style.display = 'none';
        }
    });
    // });
}
